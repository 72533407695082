/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;


@layer components {
  .message-bubble {
    @apply p-3 rounded-2xl max-w-[70%] break-words my-1;
    animation: fadeIn 0.3s ease-out;
  }

  .message-received {
    @apply bg-white self-start rounded-bl-sm shadow-sm;
  }

  .message-sent {
    @apply bg-[#DB735C] text-white self-end rounded-br-sm shadow-sm;
  }

  .message-whatsapp {
    @apply bg-[#005C4B] text-white self-end rounded-br-sm shadow-sm;
  }

  .message-end {
    @apply text-center text-gray-500 flex items-center justify-center;
  }

  .bb-btn-primary {
    @apply
    bb-flex
    bb-items-center
    bb-justify-center
    bb-rounded-full
    bb-bg-gradient-to-r
    bb-from-gradient-100
    bb-to-gradient-300
    bb-text-white
    bb-text-center
    bb-tracking-wide
    bb-font-700
    bb-uppercase
    bb-transition-all
    bb-ease-in-out
    bb-duration-100
    bb-border-none
    hover:bb-text-white
  }

  .bb-btn-primary:hover {
    background: linear-gradient(83.47deg, #EC2F73 5.13%, #EC2F73 94.87%) !important;
  }

  .bb-btn-primary-outline {
    @apply
    bb-flex
    bb-items-center
    bb-justify-center
    bb-rounded-full
    bb-border-2
    bb-border-pink-300
    bb-tracking-wide
    bb-bg-transparent
    bb-text-black
    bb-text-center
    bb-font-700
    bb-uppercase
    bb-transition-all
    bb-ease-in-out
    bb-duration-100
    hover:bb-border-pink-100
  }

  .bb-btn-small {
    @apply
    bb-h-10
    bb-px-8
    bb-text-13
    bb-leading-130
    bb-py-[18px]
  }

  .bb-btn-medium {
    @apply
    bb-h-14
    bb-px-8
    bb-text-15
    bb-leading-130
    bb-py-3
  }

  .bb-btn-large {
    @apply
    bb-h-[60px]
    bb-px-8
    bb-py-5
  }

  .bb-btn-copy {
    @apply
    bb-text-14
    bb-text-black
    bb-underline
    bb-font-700
    bb-transition-all
    bb-ease-in-out
    bb-duration-100
    hover:bb-text-pink-100
    hover:bb-underline
  }

  .bb-btn-link {
    @apply
    bb-text-12
    bb-text-gray-250
    bb-underline
    bb-font-450
    bb-transition-all
    bb-ease-in-out
    bb-duration-100
    hover:bb-text-pink-100
    hover:bb-underline
    visited:bb-text-pink-100
    visited:bb-text-pink-100
  }

  .bb-btn-copy-small {
    @apply
    bb-font-450
    bb-no-underline
  }

  .bb-btn-card-type {
    @apply
    bb-text-gray-200
    bb-bg-white/60
    bb-p-4
    bb-leading-125
    hover:bb-text-black
    hover:bb-bg-white
    bb-text-16
    bb-flex
    bb-items-center
    bb-justify-center
    bb-rounded-8
    hover:bb-cursor-pointer
    bb-transition-all
  }

  .bb-btn-card-type-active {
    @apply
    bb-text-black
    bb-bg-white
  }

  .bb-form-field{
    @apply bb-flex bb-flex-col
  }
  .bb-form-label {
    @apply bb-text-black bb-text-14 bb-leading-125 bb-font-700 bb-mb-[6px]
  }

  .bb-form-input {
    @apply
    bb-bg-white
    bb-border-gray-150
    bb-rounded
    bb-mb-[10px]
    bb-h-12
    bb-text-14
    bb-p-4
    bb-font-450
    bb-text-black
    bb-border
    bb-placeholder-gray-160
  }

  .bb-form-input-error{
    @apply bb-text-pink-100 bb-mb-[10px]
  }

  .bb-btn-form{
    @apply
    bb-flex
    bb-flex-row
    bb-justify-center
    bb-items-center
    bb-py-4
    bb-px-8
    bb-h-14
    bb-bg-gray-800
    bb-text-white
    bb-w-full
    bb-text-15
    bb-leading-130
    bb-uppercase
    bb-font-700
    bb-rounded-8
    disabled:bb-bg-gray-100
    disabled:bb-cursor-not-allowed
  }
}
@layer utilities {
  .animate-fade-in {
    animation: fadeIn 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animate-slide-in {
    animation: slideIn 0.3s ease-out;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

input::placeholder {
  color: #9CA3AF;
}

.bg-gradient-to-br {
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
